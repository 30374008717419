.terminal {
    height: 500px;
    overflow-y: auto;
    color: #C4C4C4;
    padding: 35px 45px;
    font-size: 14px;
    line-height: 1.42;
    font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier,
    monospace;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    scroll-behavior: smooth;

    bottom: 0 !important;
    position: relative;
    max-height: 71%;
}

.terminal__line {
    line-height: 2;
    white-space: pre-wrap;
}

.terminal__prompt {
    display: flex;
    align-items: center;
    margin-bottom: 1em !important;
}

.terminal__prompt__label {
    flex: 0 0 auto;
    color: #F9EF00;
}

.terminal__prompt__input {
    flex: 1;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    color: white;
}

.terminal__prompt__input input {
    flex: 1;
    width: 100%;
    background-color: transparent;
    color: white;
    border: 0;
    outline: none;
    font-size: 14px;
    line-height: 1.42;
    font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier,
    monospace;
}
.terminal_bg {
    background-color: #213154;
    opacity: 218%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
}

span.pathblock_response, span.pathblock_response span {
    color: orange !important;
}